<template>
    <!-- <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-3"> -->
        <div class="card h-100 form-rounded-left form-rounded-right form-shadow">
            <div class="card-header py-4 text-white" :class="disable ? 'card-payment-option-disabled' : product.colorClass">
                <span class="font-size-payment-option-icon d-block text-center"><i :class="product.icon"></i></span>
                <span class="font-size-payment-option-title mp-qks d-block text-center mp-font-weight-medium">{{ product.title }}</span>
            </div>

            <!-- product info -->
            <div class="card-body d-flex flex-column px-5 py-4" v-if="!isSubscription">
                <span class="d-block text-center mp-rbk font-size-expert-register-payment-option-price mp-font-weight-medium">
                    {{ new Intl.NumberFormat('de-DE').format(product.price) }} <i class="fas fa-euro-sign" style="font-size: 22px"></i>
                </span>

                <span class="d-block text-center mp-rbk font-size-expert-register-payment-option-price-description mp-font-weight-medium">{{ product.paymentPlan }} (zzgl. 20% USt.)</span>
                <span v-if="product.paymentInfosHeader" class="expert-register-payment-option-price-addon font-size-expert-register-payment-option-price-addon mp-rbk d-block text-center mp-font-weight-medium mt-2 mb-4">
                    <b>{{ product.paymentInfosHeader }}</b>
                </span>
                <span class="expert-register-payment-option-price-addon font-size-expert-register-payment-option-price-addon mp-rbk d-block text-center mp-font-weight-medium mb-4" v-else>
                    &nbsp;<br>
                </span>

                <!--
                <ul class="expert-detail-fields-of-expertise-list ps-0 mt-3 mb-5">
                    <li class="py-1" v-for="opt in productDetails" :key="`${keyPrefix}_${opt}`">
                        <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold"><i class="fas fa-check me-2"></i>{{ opt }}</span>
                    </li>
                </ul>
                -->

                <div class="row expert-detail-fields-of-expertise-list ps-0 mt-1 mb-1" v-for="opt in productDetails" :key="`${keyPrefix}_${opt}`">
                    <div class="col-2 py-1">
                        <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold"><i class="fas fa-check me-2"></i></span>
                    </div>
                    <div class="col-10 py-1">
                        <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold">{{ opt }}</span>
                    </div>
                </div>

                <div class="mt-auto d-flex justify-content-center" v-if="!disable && !hideButton">
                    <template v-if="!btnDisabled">
                        <a v-if="!upgradeButton" href="#" class="btn btn-mp-green-subscribe mp-qks mp-font-weight-medium" @click="$emit('buy')">Jetzt buchen</a>
                        <a v-else href="#" class="btn btn-mp-green-subscribe mp-qks mp-font-weight-medium" @click="$emit('upgrade')">Upgrade</a>
                    </template>
                    <a v-else href="#" class="btn btn-mp-grey-subscribe mp-qks mp-font-weight-medium">Jetzt buchen</a>
                </div>
            </div>

            <!-- current subscription info -->
            <div class="card-body d-flex flex-column px-5 py-4" v-else>
                <span class="d-block text-center mp-rbk font-size-expert-register-payment-option-price mp-font-weight-medium">
                    {{ new Intl.NumberFormat('de-DE').format(product.price) }} <i class="fas fa-euro-sign" style="font-size: 22px"></i>
                </span>
                <span class="d-block text-center mp-rbk font-size-expert-register-payment-option-price-description mp-font-weight-medium">{{ product.paymentPlan }} (zzgl. 20% USt.)</span>
                <span class="expert-register-payment-option-price-addon font-size-expert-register-payment-option-price-addon mp-rbk d-block text-center mp-font-weight-medium" v-if="product.paymentDiscount">
                    {{ product.paymentDiscount }}
                </span>
                <span class="expert-register-payment-option-price-addon font-size-expert-register-payment-option-price-addon mp-rbk d-block text-center mp-font-weight-medium" v-else>
                    &nbsp;
                </span>
                <!-- product info -->
                <div class="row expert-detail-fields-of-expertise-list ps-0 mt-1 mb-1" v-for="opt in productDetails" :key="`${keyPrefix}_${opt}`">
                    <div class="col-2 py-1">
                        <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold"><i class="fas fa-check me-2"></i></span>
                    </div>
                    <div class="col-10 py-1">
                        <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold">{{ opt }}</span>
                    </div>
                </div>
                <!-- subscription info -->
                <ul class="expert-detail-fields-of-expertise-list ps-0 mt-3 mb-4 text-center">
                    <li class="py-1">
                        <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold">Startdatum: {{ formatDate(startDate) }}</span>
                    </li>
                    <li class="py-1">
                        <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold">Enddatum: {{ formatDate(endDate) }}</span>
                    </li>
                    <li class="py-1" v-if="hasPendingDiscount">
                        <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold" style="color: #146c43">{{ pendingDiscountTitle }}</span>
                    </li>
                </ul>
                <div class="mt-auto d-flex justify-content-center" v-if="!canceled">
                    <a v-if="!btnDisabled" href="#" class="btn btn-mp-red-subscribe mp-qks mp-font-weight-medium" @click="doCancel">Abo beenden</a>
                    <a v-else href="#" class="btn btn-mp-grey-subscribe mp-qks mp-font-weight-medium" style="cursor: not-allowed">Abo beenden</a>
                    <a v-if="!hasPendingDiscount" href="#" class="btn btn-mp-orange-subscribe mp-qks mp-font-weight-medium ms-1" @click="applyDiscount">Gutschein</a>
                </div>
                <div class="mt-auto d-flex justify-content-center" v-else>
                    <span class="font-size-expert-register-payment-option-list-item mp-qks mp-font-weight-semi-bold">Abo wurde beendet.</span>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>



<script>
    import {mixinBasics} from "../mixins/basics";


    export default {
        name: "ProductCard",

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'productCard',
                btnDisabled: false
            };
        },

        props: {
            product: {
                type: Object,
                required: true
            },
            disable: {
                type: Boolean,
                required: false,
                default: false
            },
            hideButton: {
                type: Boolean,
                required: false,
                default: false
            },
            isSubscription: {
                type: Boolean,
                required: false,
                default: false
            },
            startDate: {
                required: false,
                default: undefined
            },
            endDate: {
                required: false,
                default: undefined
            },
            canceled: {
                required: false,
                default: undefined
            },
            upgradeButton: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        computed: {
            productDetails() {
                return this.product.paymentInfos.split(',')
            },

            hasPendingDiscount() {
                return this.profile.hasPendingDiscount;
            },

            pendingDiscountTitle() {
                return this.profile.pendingDiscountTitle;
            }
        },

        methods: {
            doCancel() {
                this.btnDisabled = true;
                this.$emit('cancel');
            },

            applyDiscount() {
                this.$emit('apply-discount');
            }
        }
    }
</script>




<style scoped>

</style>